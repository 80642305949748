import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components/macro'

// Components
import SeoComponent from '@components/SeoComponent/SeoComponent'
import { ContentComponent } from '@components/MdxComponents'
import SpacerComponent from '@components/SpacerComponent'

type CaseStudyProps = {
  data: {
    mdx: Page
  }
  path: string
  uri: string
  params: object
  pageResources: any
  navigate: any
  pageContext: {
    id: string
    frontmatter: {
      slug: string
    }
  }
}

type Page = {
  body: string
  frontmatter: {
    slug: string
    metaTitle: string
    metaDescription: string
    metaImage: string
  }
}

const CaseStudyTemplate: React.FC<CaseStudyProps> = props => {
  const page = props.data.mdx
  const pageMeta = page.frontmatter

  return (
    <Article>
      <SeoComponent
        title={pageMeta.metaTitle}
        description={pageMeta.metaDescription}
        image={pageMeta.metaImage}
      />

      <ContentComponent body={page.body} />
      <SpacerComponent bottom='xl2' />
    </Article>
  )
}

export default CaseStudyTemplate

const Article = styled.article`
  padding-top: ${({ theme }) => theme.spacing.xl2};
`

export const pageQuery = graphql`
  query MdxPage($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        metaDescription
        metaImage
        metaTitle
      }
      body
    }
  }
`
